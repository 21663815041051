import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useMatch, useNavigate } from "react-router-dom";
import {
  selectUserData,
  setUserData,
} from "../../../utils/store/slice/userData";
import AuthService from "../../../services/AuthService";
import LocalStorage from "../../../services/LocalStorage";
import ProfileService from "../../../services/ProfileService";
import SectionLoader from "../../../components/SectionLoader";

import "./style.scss";

interface AuthGuardProps {
  component: JSX.Element;
}

const AuthGuard: React.FC<AuthGuardProps> = ({ component }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [redirectTo, setRedirectTo] = useState("");
  const setUserDataDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const competitionDetailsPageMatch = useMatch(
    "/competition/details/:contestId",
  );

  const userData = useSelector(selectUserData);

  useEffect(() => {
    if (location.pathname) {
      setRedirectTo(location.pathname);
    } else {
      setRedirectTo("/account/dashboard");
    }
  }, [location.pathname]);

  const goToAuthentication = () => {
    LocalStorage.clear();
    navigate("/authentication", {
      replace: true,
      state: {
        redirectTo,
      },
    });
  };

  const refreshAccessToken = () => {
    const refreshToken = LocalStorage.get.refreshToken();
    if (!refreshToken) {
      goToAuthentication();
      return;
    }
    AuthService.refreshAccessToken(refreshToken)
      .then((res) => {
        LocalStorage.set.accessToken(res.data.access);
      })
      .catch(() => {
        goToAuthentication();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getUserData = async () => {
    ProfileService.getProfileDetails()
      .then((res: any) => {
        setUserDataDispatch(setUserData(res.data.data));
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            refreshAccessToken();
          } else {
            goToAuthentication();
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const isUserLoggedIn = () => {
    let accessToken = LocalStorage.get.accessToken();
    let refreshToken = LocalStorage.get.refreshToken();
    if (accessToken) {
      if (userData.userId === "") {
        getUserData();
      }
      return true;
    } else if (refreshToken) {
      refreshAccessToken();
      getUserData();
      return true;
    } else {
      return false;
    }
  };

  const getCallbackPath = useCallback(
    () => location.state?.callbackPath,
    [location],
  );

  const getRedirectPath = useCallback(
    () => location.pathname,
    [location.pathname],
  );

  useEffect(() => {
    if (userData.userId !== "") {
      setIsLoading(false);
    }
  }, [userData.userId]);

  const getUsersPhoneNumberFromLocationState = useCallback(
    () => {
      const phoneNumber = location.state?.usersPhoneNumber;
      return phoneNumber;
    },
    [location.state],
  );

  return isUserLoggedIn() ? (
    !isLoading ? (
      component
    ) : (
      <SectionLoader />
    )
  ) : competitionDetailsPageMatch ? (
    component
  ) : (
    <Navigate
      to="/authentication"
      replace
      state={{
        redirectTo: getRedirectPath(),
        callbackPath: getCallbackPath(),
        usersPhoneNumber: getUsersPhoneNumberFromLocationState(),
      }}
    />
  );
};

export default AuthGuard;
