import React, { useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setPartnerDomain } from "./utils/store/slice/appConfig";
import { platforms } from "./utils/platformNames";

const PlatformSwitcher = () => {
  const [selectedPlatform, setSelectedPlatform] = useState(platforms[0].value);

  const appConfigDispatch = useDispatch();

  const handlePlatformChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedPlatform(e.target.value);
  };

  useEffect(() => {
    appConfigDispatch(setPartnerDomain(selectedPlatform));
  }, [appConfigDispatch, selectedPlatform]);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <select
          className="fs-6 w-100 p-2"
          value={selectedPlatform}
          onChange={handlePlatformChange}
        >
          {platforms.map((platform) => (
            <option
              key={platform.value}
              className="p-2 fs-6"
              value={platform.value}
            >
              {platform.label}
            </option>
          ))}
        </select>
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="floating-button-platform-switcher">
      <OverlayTrigger trigger="click" placement="auto-start" overlay={popover}>
        <button>Switch platform</button>
      </OverlayTrigger>
    </div>
  );
};

export default PlatformSwitcher;
