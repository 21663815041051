export const DARK_COLOR_THRESHOLD = 100;
export const SHADOW_COLOR_THRESHOLD = 80;
export const EXTRA_LIGHT_COLOR_THRESHOLD = 0;
export const LIGHT_COLOR_THRESHOLD = -30;

export const DEFAULT_PRIMARY_COLOR = '#a429d9';
export const DEFAULT_PRIMARY_COLOR_EXTRA_LIGHT = '#efcaff56';
export const DEFAULT_PRIMARY_SHADOW_COLOR = "#8112b0";
export const DEFAULT_PRIMARY_COLOR_DARK = "#6f1f9e";

export const DEFAULT_SECONDARY_COLOR = "#05c7f2";
export const DEFAULT_SECONDARY_COLOR_EXTRA_LIGHT = "#c5f2ff56";
export const DEFAULT_SECONDARY_COLOR_DARK = "#009fc2";
export const DEFAULT_SECONDARY_SHADOW_COLOR = "#009fc2";

export const DEFAULT_HEADING_FONT_COLOR = "#0a174c";
export const DEFAULT_BODY_FONT_COLOR = "#586185";

export const DEFAULT_ACCENT_COLOR = "#f2c705";

export const CompetitionType = {
  live: {
    name: "Current Contests",
    value: "live",
  },
  expired: {
    name: "Expired Contests",
    value: "expired",
  }
}

export const CONTEST_SNAPSHOT_DIV_ID = "contestSnapshotSection_lading_page";
export const THEME_AND_GUIDELINE_DIV_ID = "themeAndGuidelineDivId";
export const HOW_TO_SUBMIT_DIV_ID = "howToSubmitDivId";
export const CONTEST_TIMELINE_DIV_ID = "contestTimelineDivId";

export const LOCAL_STORAGE_KEYS = {
  partnerDomain: "partnerDomain",
  userData: "userData",
}

export const MIN_AGE = 1;
export const MAX_AGE = 100;

export const GRADES = [
  "Yet to Start School",
  "Nursery / Playschool / Pre-School",
  "Montessori 1",
  "Montessori 2",
  "LKG",
  "UKG",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "Post Standard 10",
  "Homeschooled",
  "Others",
];

export const SECTIONS = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "Not Applicable",
];

export enum PRE_REGISTRATION_FORM_TEMPLATES {
  DEFAULT = "DEFAULT",
  TWO_STEP_FORM = "TWO_STEP_FORM",
  FORM_WITH_OTP_VERIFICATION = "FORM_WITH_OTP_VERIFICATION",
}

export const userGivenTypeToMIMEType = (generalType: string) => {
  const obj: Record<string, string[]> = {
    image: [
      "image",
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/svg+xml",
      "image/webp",
      "image/bmp",       // Bitmap images
      "image/tiff",      // TIFF images
      "image/x-icon",    // Icon images
      "image/heic",      // High Efficiency Image Format
      "image/heif"       // Another High Efficiency Image Format
    ],
    video: [
      "video",
      "video/mp4",
      "video/mpeg",
      "video/ogg",
      "video/webm",
      "video/quicktime",
      "video/x-msvideo",    // AVI format
      "video/x-flv",        // FLV format
      "application/vnd.apple.mpegurl", // HLS stream
      "application/x-mpegURL",         // HLS stream alternative
      "video/3gpp",         // 3GPP format
      "video/x-matroska"    // MKV format
    ],
    audio: [
      "audio",
      "application/x-cdf",
      "audio/mpeg",      // MP3 audio
      "audio/ogg",       // Ogg Vorbis audio
      "audio/wav",       // WAV audio
      "audio/x-ms-wma"   // Windows Media Audio
    ],
    document: [
      "application/msword", // DOC
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX
      "application/pdf", // PDF
    ]
  };


  return obj[generalType];
}