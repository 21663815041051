export const APP_ELEMENTS_AND_CLASS = {
  HEADLINE: { label: "Headline", value: "headline" },
  HEADING: { label: "Heading", value: "heading" },
  BODY: { label: "Body", value: "body" },
  H1: { label: "H1 Heading", value: "h1" },
  H2: { label: "H2 Heading", value: "h2" },
  H3: { label: "H3 Heading", value: "h3" },
  H4: { label: "H4 Heading", value: "h4" },
  H5: { label: "H5 Heading", value: "h5" },
  H6: { label: "H6 Heading", value: "h6" },
  LINK: { label: "Link", value: "a" },
  PARAGRAPH: { label: "Paragraph", value: "p" },
  SPAN: { label: "Span", value: "span" },
  DIV: { label: "Div", value: "div" },
  BOLD: { label: "Bold", value: "b" },
  STRONG: { label: "Strong", value: "strong" },
  ITALIC: { label: "Italic", value: "i" },
  EMPHASIS: { label: "Emphasis", value: "em" },
  SMALL: { label: "Small", value: "small" },
  MARK: { label: "Mark", value: "mark" },
  UNDERLINE: { label: "Underline", value: "u" },
  BLOCKQUOTE: { label: "Blockquote", value: "blockquote" },
  QUOTE: { label: "Inline Quote", value: "q" },
  CITE: { label: "Citation", value: "cite" },
  UNORDERED_LIST: { label: "Unordered List", value: "ul" },
  ORDERED_LIST: { label: "Ordered List", value: "ol" },
  LIST_ITEM: { label: "List Item", value: "li" },
  CODE: { label: "Inline Code", value: "code" },
  PRE: { label: "Preformatted Text", value: "pre" },
  KEYBOARD: { label: "Keyboard Input", value: "kbd" },
  SAMPLE: { label: "Sample Output", value: "samp" },
  SUPERSCRIPT: { label: "Superscript", value: "sup" },
  SUBSCRIPT: { label: "Subscript", value: "sub" },
  LINE_BREAK: { label: "Line Break", value: "br" },
  HORIZONTAL_RULE: { label: "Horizontal Rule", value: "hr" },
  ADDRESS: { label: "Address", value: "address" },
  ABBREVIATION: { label: "Abbreviation", value: "abbr" },
  CUSTOM_FONT: { label: "Custom Font", value: "custom-font" },
};


export enum FONT_STYLES {
  NORMAL = "normal",
  ITALIC = "italic",
  OBLIQUE = "oblique",
}