import { ContestSubmissionMode } from "@wizkidscarnival/wizkids-carnival-commons/constants";
import {
  ChildData,
  CompetitionCardDetails,
  CompetitionDetailsData,
  ContestQualificationLevel,
  ContestSlotsDateAvailability,
  SlotTime,
  SubscriptionStatus,
  UserData,
} from "../models";
import { OtherBrandContest } from "../models/UIModels";
import { ChildDetailsFormState } from "../pages/account/settings/components/ChildDetailsForm/utils/reducer/ChildFormState";
import { PersonalProfileDetailsFormState } from "../pages/account/settings/components/ParentDetailForm/utils/reducer/PersonalProfileDetailsFormState";
import {
  capitalizeEachWordInString,
  hasDatePassed,
} from "../utils/commonFunctions";

const formatContestQualificationLevel = (data: any) => ({
  id: data?.id,
  levelName: data?.level_name,
  levelNumber: data?.level_number,
  minimumQualificationLevel: data?.minimum_qualification_level,
});

const convertUserResponseDataToUserData = (data: any): UserData => {
  return {
    userId: data?.user_id || "",
    name: capitalizeEachWordInString(data.fullname || ""),
    email: data?.email || "",
    phoneNumber: data?.mobile || '',
    profilePictureUrl: data?.profile_image || "",
    children:
      data?.children.length > 0
        ? data.children.map((childData: any) => {
          const childObj: ChildData = {
            id: childData?.id || "",
            childId: childData?.child_id || "",
            fullName: capitalizeEachWordInString(childData?.fullname || ""),
            dateOfBirth: childData?.date_of_birth || "",
            profileImage: childData?.profile_image || "",
            age: childData?.age || "",
            grade: childData?.grade || "",
            schoolName: childData?.school_name || "",
            fieldOfInterest: childData?.field_of_interest || [],
            teacherMobileNumber: childData?.teacher_mobile_number || "",
            contestQualificationLevel: childData?.contest_qualification_level ? formatContestQualificationLevel(
              childData?.contest_qualification_level,
            ) : {} as ContestQualificationLevel,
            section: childData?.section || "",
            teacherName: childData?.teacher_name || "",
          };

          return childObj;
        })
        : [],
    subscription: {
      name: data?.subscription?.name || "",
      detailedBenefits: data?.subscription?.detailed_benefits || "",
      perks: data?.subscription?.perks?.split("\r\n"),
      subscriptionExpiryDate: data?.subscription_expiry_date || "",
      isPremiumUser: data?.is_premium_user || false,
      isSubscribed: data?.is_subscribed || false,
      subscriptionStartDate: data?.subscription_start_date || "",
    },
    address: data?.address || "",
    pinCode: data?.pin_code || "",
    city: data?.city || "",
    alternatePhoneNumber: data?.alternate_mobile || "",
    source: data?.source || "",
    agentName: data?.agent_name || "",
  };
};

const convertCompetitionDetailResponseToCompetitionDetails = (
  responseData: any,
): CompetitionCardDetails => {
  return {
    contestId: responseData.contest_id,
    amount: responseData.amount_in_INR,
    img: responseData.image_url,
    level: responseData.level,
    ages: `${responseData.min_age} - ${responseData.max_age}`,
    submissionStartDate: responseData.submission_start,
    submitByDate: responseData.submit_by,
    name: responseData.title,
    isEnrolled: responseData.enrolled,
    certificateUploaded: responseData.certificate_uploaded,
    certificateAndGiftCardDate: responseData.certification_and_gc_date,
    resultDeclared: responseData.result_declared,
    resultDate: responseData.result_date,
    themeGuidelinesPDFLink: responseData.theme_guidelines_pdf,
    isLive: hasDatePassed(responseData.submission_start, { allowTodaysDate: true }) && !hasDatePassed(responseData.submit_by),
    disabled: !responseData.is_active,
    contestType: {
      key: responseData.contest_type?.key || "",
      name: responseData.contest_type?.name || "",
    },
    isFileUploaded: responseData.is_file_uploaded,
    isAFreeContest: responseData.is_contest_free,
    pinCodes: responseData.pincode,
    contestQualificationLevel: formatContestQualificationLevel(
      responseData.contest_qualification_level,
    ),
    submissionMode: responseData.submission_mode || ContestSubmissionMode.OFFLINE,
    maximumUploadForAChild: responseData.max_submissions_per_child || -1
  };
};

const convertCompetitionsResponseToCompetitionCardsDetails = (
  responseData: any,
): CompetitionCardDetails[] => {
  if (!responseData.results) {
    return [];
  }

  return responseData.results.map(
    (data: any): CompetitionCardDetails =>
      convertCompetitionDetailResponseToCompetitionDetails(data),
  );
};

const formatFAQ = (faqResponse: Record<string, any>[]) => {
  try {
    return faqResponse.map((item) => {
      return {
        id: item?.id,
        question: item?.faq_question,
        answer: item?.faq_answer,
      };
    });
  } catch (error) {
    return [];
  }
};

const formatThemeData = (data: Record<string, any>[]) => {
  return data.map((item) => {
    return {
      imgSrc: item?.image_url,
      title: `${item?.min_age}-${item?.max_age} Years`,
      description: item?.guidelines,
    };
  });
};

export const convertContestDetailsResponseToContestDetails = (
  data: any,
): CompetitionDetailsData => {
  return {
    isEnrolled: data?.enrolled,
    contestId: data?.contest_id,
    title: data?.title,
    description: data?.description,
    thingsToKnow: data?.things_to_know,
    minAge: data?.min_age,
    maxAge: data?.max_age,
    level: data?.level,
    amount: data?.amount,
    theme: {
      title: data?.theme_title,
      description: data?.theme_description,
      data: formatThemeData(data?.theme),
    },
    submitDate: {
      date: data?.submit_by?.split("-")[2],
      month: data?.submit_by?.split("-")[1],
      year: data?.submit_by?.split("-")[0],
    },
    resultDate: {
      date: data?.result_date?.split("-")[2],
      month: data?.result_date?.split("-")[1],
      year: data?.result_date?.split("-")[0],
    },
    certificationAndGiftCardDate: {
      date: data?.certification_and_gc_date?.split("-")[2],
      month: data?.certification_and_gc_date?.split("-")[1],
      year: data?.certification_and_gc_date?.split("-")[0],
    },
    publicationDate: {
      date: data?.publication_date?.split("-")[2],
      month: data?.publication_date?.split("-")[1],
      year: data?.publication_date?.split("-")[0],
    },
    prize: { ...data?.prize[0] },
    FAQ: formatFAQ(data?.faq),
    themeGuidelinesPdfLink: data?.theme_guidelines_pdf,
  };
};

const convertResponseSubscriptionDetailsToSubscriptionStatus = (
  data: any,
): SubscriptionStatus => {
  return {
    enrollmentLeft: data.enrollment_left,
    isComboAvailable: data.is_subscribed,
    isPremiumUser: data.is_premium_user,
  };
};

const convertPersonalFormDetailsToUserDetailsPayload = (
  formState: PersonalProfileDetailsFormState,
): Record<string, any> => {
  return {
    profile_image:
      formState.profileImgSrc === "" ? null : formState.profileImgSrc,
    fullname: formState.fullName.value,
    mobile: formState.mobile.value,
    email: formState.email.value,
    address: formState.address.value,
    pin_code: formState.pinCode.value,
    source: formState.source.value === "" ? null : formState.source.value,
    city: formState.city.value,
    agent_name: formState.agent.value === "" ? null : formState.agent.value,
    alternate_mobile:
      formState.alternateMobile.value === ""
        ? null
        : formState.alternateMobile.value,
  };
};

const convertChildFormDetailsToChildDetailsPayload = (
  formState: ChildDetailsFormState["formState"],
): Record<string, any> => {
  const childDetails: Record<string, any> = {
    fullname: formState.fullName.value,
    date_of_birth: formState.dateOfBirth.value,
    grade: formState.grade.value,
    school_name: formState.nameOfSchool.value,
    section: formState.section.value,
    field_of_interest: formState.fieldOfInterest.value.map(
      (data) => data.value,
    ),
    profile_image:
      formState.profileImgSrc === "" ? null : formState.profileImgSrc,
    teacher_name: formState.nameOfTeacher.value,
    teacher_mobile_number: formState.contactNumberOfTeacher.value,
  };
  if (formState.id.value) {
    childDetails.id = formState.id.value;
  }

  return childDetails;
};

const convertOtherActiveBrandContestsResponseToOtherBrandContests = (responseData: any): OtherBrandContest[] => {
  return responseData.map((data: any) => {
    return {
      domain: data?.domain || "",
      logoSrc: data?.logo[0]?.logo || "",
      contestName: data?.league_name || "",
      venueDetails: data?.venue_details || "",
      brandURL: data?.brand_base_url || "",
    };
  });
}

const convertSlotAvailableDatesResponseToSlotAvailableDates = (responseData: any[]): ContestSlotsDateAvailability[] => {
  return responseData.map(data => ({
    contestId: data.contest_id,
    availableDates: data.date,
  }));
}

const convertSlotTimingResponseToSlotTiming = (responseData: any[]): SlotTime[] => {
  return responseData.map(data => ({
    id: data.id,
    date: data.date,
    startTime: data.start_time,
    endTime: data.end_time,
    value: `${data.start_time} - ${data.end_time}`,
  }));
}

export {
  convertUserResponseDataToUserData,
  convertCompetitionDetailResponseToCompetitionDetails,
  convertCompetitionsResponseToCompetitionCardsDetails,
  convertResponseSubscriptionDetailsToSubscriptionStatus,
  convertPersonalFormDetailsToUserDetailsPayload,
  convertChildFormDetailsToChildDetailsPayload,
  convertOtherActiveBrandContestsResponseToOtherBrandContests,
  convertSlotAvailableDatesResponseToSlotAvailableDates,
  convertSlotTimingResponseToSlotTiming,
};
