import { createSlice } from "@reduxjs/toolkit";
import { ChildData } from "../../../models";
import { initialChildState } from "../../../models/initialStates";

interface SelectedChildData {
  childData: ChildData;
  showChildSelectorDropdown: boolean;
}

const selectedChildInitialData = {
  childData: initialChildState,
  showChildSelectorDropdown: false,
}

export const selectedChildSlice = createSlice({
  name: "selectedChild",
  initialState: selectedChildInitialData,
  reducers: {
    setSelectedChildDataToStore: (state, action) => {
      state.childData = action.payload;
    },
    toggleChildSelectorDropdown: (state) => {
      state.showChildSelectorDropdown = !state.showChildSelectorDropdown;
    },
    openChildSelectorDropdown: (state) => {
      state.showChildSelectorDropdown = true;
    },
    closeChildSelectorDropdown: (state) => {
      state.showChildSelectorDropdown = false;
    },
  },
});

export const selectSelectedChildData = (state: { selectedChild: SelectedChildData }) => state.selectedChild.childData;
export const selectChildSelectorDropdownState = (state: { selectedChild: SelectedChildData }) => state.selectedChild.showChildSelectorDropdown;

export const { setSelectedChildDataToStore, toggleChildSelectorDropdown, openChildSelectorDropdown, closeChildSelectorDropdown } = selectedChildSlice.actions;

export default selectedChildSlice.reducer;
