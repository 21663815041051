import { AppConfig } from "../models";
import { calculateLuminance } from "./commonFunctions";
import { DARK_COLOR_THRESHOLD, EXTRA_LIGHT_COLOR_THRESHOLD, DEFAULT_PRIMARY_COLOR, SHADOW_COLOR_THRESHOLD, DEFAULT_PRIMARY_COLOR_EXTRA_LIGHT, DEFAULT_PRIMARY_SHADOW_COLOR, DEFAULT_PRIMARY_COLOR_DARK, DEFAULT_SECONDARY_COLOR, DEFAULT_SECONDARY_COLOR_EXTRA_LIGHT, DEFAULT_SECONDARY_COLOR_DARK, DEFAULT_SECONDARY_SHADOW_COLOR, DEFAULT_ACCENT_COLOR, LIGHT_COLOR_THRESHOLD, DEFAULT_HEADING_FONT_COLOR, DEFAULT_BODY_FONT_COLOR } from "./constants";

/**
 * 
 * @param color Color in hexadecimal format
 * @param threshold Integer value to control the darkness of the color
 * @param opacity Opacity value for the color (default is 1)
 * @returns Color in rgba format
 */
export const createColorVariant = (color: string, threshold: number, opacity: number = 1) => {
  // Remove the '#' symbol
  color = color.slice(1);

  // Convert hexadecimal color to RGB
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);

  // Calculate darker shadow by reducing each RGB component
  const variantForR = Math.max(0, r - threshold); // Adjust this value to control darkness
  const variantForG = Math.max(0, g - threshold);
  const variantForB = Math.max(0, b - threshold);

  // Convert shadow RGB back to hexadecimal
  const variantColor = `rgba(${variantForR}, ${variantForG}, ${variantForB}, ${opacity})`;

  return variantColor;

}

const setButtonTextColor = (buttonColor: string, cssVariableName: string) => {
  const rgbColorString = convertHexColorCodeToRGBColorCode(buttonColor);
  const [r, g, b] = rgbColorString.substring(4, rgbColorString.length - 1).split(",").map((color) => parseInt(color));

  const luminousThreshold = 186;
  const luminance = calculateLuminance(r, g, b);

  if (luminance > luminousThreshold) {
    // set text color to dark
    document.documentElement.style.setProperty(cssVariableName, "#000");
  } else {
    // set text color to light
    document.documentElement.style.setProperty(cssVariableName, "#fff");
  }
}

export const setCSSColorVariables = (colors: AppConfig['color']) => {
  document.documentElement.style.setProperty(
    "--primary-color",
    colors.primary || DEFAULT_PRIMARY_COLOR,
  );

  document.documentElement.style.setProperty(
    "--primary-color-light",
    colors.primary ? createColorVariant(colors.primary, LIGHT_COLOR_THRESHOLD) : DEFAULT_PRIMARY_COLOR,
  );

  document.documentElement.style.setProperty(
    "--primary-color-extra-light",
    colors.primary ? createColorVariant(colors.primary, EXTRA_LIGHT_COLOR_THRESHOLD, 0.03) : DEFAULT_PRIMARY_COLOR_EXTRA_LIGHT,
  );

  document.documentElement.style.setProperty(
    "--primary-shadow-color",
    colors.primary ? createColorVariant(colors.primary, SHADOW_COLOR_THRESHOLD) : DEFAULT_PRIMARY_SHADOW_COLOR,
  );

  document.documentElement.style.setProperty(
    "--primary-color-dark",
    colors.primary ? createColorVariant(colors.primary, DARK_COLOR_THRESHOLD) : DEFAULT_PRIMARY_COLOR_DARK,
  );

  document.documentElement.style.setProperty(
    "--secondary-color",
    colors.secondary || DEFAULT_SECONDARY_COLOR,
  );

  document.documentElement.style.setProperty(
    "--secondary-color-light",
    colors.secondary ? createColorVariant(colors.secondary, EXTRA_LIGHT_COLOR_THRESHOLD, 0.3) : DEFAULT_SECONDARY_COLOR,
  );

  document.documentElement.style.setProperty(
    "--secondary-color-extra-light",
    colors.secondary ? createColorVariant(colors.secondary, EXTRA_LIGHT_COLOR_THRESHOLD, 0.03) : DEFAULT_SECONDARY_COLOR_EXTRA_LIGHT,
  );

  document.documentElement.style.setProperty(
    "--secondary-color-dark",
    colors.secondary ? createColorVariant(colors.secondary, DARK_COLOR_THRESHOLD) : DEFAULT_SECONDARY_COLOR_DARK,
  );

  document.documentElement.style.setProperty(
    "--secondary-shadow-color",
    colors.secondary ? createColorVariant(colors.secondary, SHADOW_COLOR_THRESHOLD) : DEFAULT_SECONDARY_SHADOW_COLOR,
  );

  document.documentElement.style.setProperty(
    "--accent-color",
    colors.accent || DEFAULT_ACCENT_COLOR,
  );

  document.documentElement.style.setProperty(
    "--header-font-color",
    colors.headingFontColor || DEFAULT_HEADING_FONT_COLOR,
  );

  document.documentElement.style.setProperty(
    "--body-font-color",
    colors.bodyFontColor || DEFAULT_BODY_FONT_COLOR,
  );

  setButtonTextColor(colors.primary || DEFAULT_PRIMARY_COLOR, "--primary-button-text-color");
  setButtonTextColor(colors.secondary || DEFAULT_SECONDARY_COLOR, "--secondary-button-text-color");

}

export const convertRGBToHex = (rgb: string) => {
  const [r, g, b] = rgb.split(',').map((color) => parseInt(color));
  return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
}

export const convertRGBAtoHex = (rgba: string) => {
  const [r, g, b, a] = rgba.split(',').map((color) => parseInt(color));
  return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}${a.toString(16)}`;
}

export const convertHexColorCodeToRGBColorCode = (hexColorCode: string) => {
  const r = parseInt(hexColorCode.slice(1, 3), 16);
  const g = parseInt(hexColorCode.slice(3, 5), 16);
  const b = parseInt(hexColorCode.slice(5, 7), 16);
  return `rgb(${r}, ${g}, ${b})`;
}