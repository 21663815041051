import Typography from "../../../models/typography";
import { APP_ELEMENTS_AND_CLASS, FONT_STYLES } from "../constants";

const swiggyInstamartFont: Typography[] = [
  {
    element: APP_ELEMENTS_AND_CLASS.BODY.value,
    src: "/assets/fonts/Gilroy/Gilroy-Light.otf",
    fontFamily: "gilroy-light",
    fontWeight: 'normal',
    style: FONT_STYLES.NORMAL,
  },
  {
    element: APP_ELEMENTS_AND_CLASS.HEADLINE.value,
    src: "/assets/fonts/Gilroy/Gilroy-ExtraBold.otf",
    fontFamily: "gilroy-extrabold",
    fontWeight: '800',
    style: FONT_STYLES.NORMAL,
    isCustomTag: true
  },
  {
    element: APP_ELEMENTS_AND_CLASS.H1.value,
    src: "/assets/fonts/Gilroy/Gilroy-Bold.otf",
    fontFamily: "gilroy-bold",
    fontWeight: '500',
    style: FONT_STYLES.NORMAL
  },
  {
    element: APP_ELEMENTS_AND_CLASS.H2.value,
    src: "/assets/fonts/Gilroy/Gilroy-Bold.otf",
    fontFamily: "gilroy-bold",
    fontWeight: '500',
    style: FONT_STYLES.NORMAL
  },
  {
    element: APP_ELEMENTS_AND_CLASS.H3.value,
    src: "/assets/fonts/Gilroy/Gilroy-SemiBold.otf",
    fontFamily: "gilroy-semi-bold",
    fontWeight: '500',
    style: FONT_STYLES.NORMAL
  },
  {
    element: APP_ELEMENTS_AND_CLASS.H4.value,
    src: "/assets/fonts/Gilroy/Gilroy-SemiBold.otf",
    fontFamily: "gilroy-semi-bold",
    fontWeight: '500',
    style: FONT_STYLES.NORMAL
  },
  {
    element: APP_ELEMENTS_AND_CLASS.H5.value,
    src: "/assets/fonts/Gilroy/Gilroy-Medium.otf",
    fontFamily: "gilroy-medium",
    fontWeight: '500',
    style: FONT_STYLES.NORMAL
  },
  {
    element: APP_ELEMENTS_AND_CLASS.H6.value,
    src: "/assets/fonts/Gilroy/Gilroy-Medium.otf",
    fontFamily: "gilroy-medium",
    fontWeight: '500',
    style: FONT_STYLES.NORMAL
  },
  {
    element: APP_ELEMENTS_AND_CLASS.CUSTOM_FONT.value,
    src: "/assets/fonts/Gilroy/Gilroy-Regular.otf",
    fontFamily: "gilroy-regular",
    fontWeight: 'normal',
    style: FONT_STYLES.NORMAL,
    isCustomTag: true
  }
]

export default swiggyInstamartFont;