export const platformNames = {
  MIS: {
    label: "MIS",
    value: "mis",
    order: 3,
  },
  GARFIELD_MOVIE: {
    label: "Garfield",
    value: "garfield",
    order: 4,
  },
  MC_DONALD: {
    label: "Mc Donald's",
    value: "mcdonalds",
    order: 5,
  },
  CITY_CENTER: {
    label: "City Center Mall, Mangalore",
    value: "citycenter",
    order: 6,
  },
  DUCKLINGS: {
    label: "Ducklings",
    value: "ducklings",
    order: 7,
  },
  SWIGGY_REG: {
    label: "Swiggy Registration",
    value: "swiggyregistrations",
    order: 2,
  },
  SWIGGY: {
    label: "Swiggy",
    value: "swiggy",
    order: 1,
  },
  FIZA: {
    label: "Fiza Nexus",
    value: "fiza-nexus",
    order: 8,
  }
}

export const platforms = Object.values(platformNames)
  .sort((a, b) => a.order - b.order)
  .map((platform) => ({
    label: platform.label,
    value: platform.value,
  }));


export default platformNames;