import React from "react";
import { Link } from "react-router-dom";

interface RedirectLink {
  text: string;
  url: string;
}

interface ErrorPageProps {
  errorCode: number;
  errorMessage?: string;
  redirectLinks?: RedirectLink[];
  hideLinks?: boolean;
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  errorCode,
  errorMessage,
  redirectLinks,
  hideLinks,
}) => {
  return (
    <div className="w-100 d-flex flex-column gap-4 justify-content-center align-items-center error-page">
      <div className="display-1 text-danger fw-bold custom-font">
        {errorCode}
      </div>
      <div className="fs-4 text-center px-3">{errorMessage}</div>
      {!hideLinks && (
        <div className="text-center gap-3 d-flex flex-column">
          {redirectLinks?.map((link) => (
            <Link key={link.url} to={link.url} className="primary-button">
              {link.text}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

ErrorPage.defaultProps = {
  errorMessage: "Oops! We cannot find the page you are looking for.",
  redirectLinks: [
    {
      text: "Go To Home",
      url: "/",
    },
    {
      text: "Go To Registration Page",
      url: "/registration",
    },
  ],
  hideLinks: false,
};

export default ErrorPage;
