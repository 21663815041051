import React, { Suspense, lazy, useCallback, useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import AppLoader from "../components/AppLoader";
import ErrorPage from "../pages/ErrorPage";
import AuthLayout from "./layout/auth";
import AuthGuard from "./layout/account/AuthGuard";
import { useSelector } from "react-redux";
import {
  selectAppsRedirectionLink,
  selectAppStatus,
  selectIsBrandContestActive,
} from "../utils/store/slice/appConfig";
import { AppStatus } from "../models";

const Home = lazy(() => import("../pages/home"));
const AccountLayout = lazy(() => import("./layout/account"));
const CompetitionDetails = lazy(() => import("../pages/competition-details"));

const LoginOrRegister = lazy(() => import("../pages/auth/loginOrRegister"));
const BasicUserDetailsForm = lazy(
  () => import("../pages/account/basicUserDetailsForm"),
);

const TermsAndConditions = lazy(() => import("../pages/terms-and-conditions"));
const ContestOver = lazy(() => import("../pages/contest-over"));
const ComingSoon = lazy(() => import("../pages/ComingSoon"));

interface ScrollToTopProps {
  children: React.ReactNode;
}

const ScrollToTop: React.FC<ScrollToTopProps> = (props) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>;
};

const AppRouter = () => {
  const isBrandContestActive = useSelector(selectIsBrandContestActive);
  const appStatus = useSelector(selectAppStatus);
  const appRedirectionLink = useSelector(selectAppsRedirectionLink);

  const redirectToLink = useCallback(() => {
    if (appRedirectionLink) {
      window.location.href = appRedirectionLink;
    } else {
      window.location.href = "/";
    }
  }, [appRedirectionLink]);

  const handleAppStatus = useCallback(() => {
    if (appStatus === AppStatus.IN_DEVELOPMENT) {
      return <ComingSoon test="Dev" />;
    } else if (appStatus === AppStatus.IN_MAINTENANCE) {
      return <ComingSoon test="Maintain" />;
    } else if (appStatus === AppStatus.REDIRECTING) {
      redirectToLink();
      return <AppLoader />;
    } else {
      return null;
    }
  }, [appStatus, redirectToLink]);

  if (!isBrandContestActive) {
    return <ContestOver />;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<AppLoader />}>
        {handleAppStatus()}
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/registration"
              element={<Home hasRegistrationForm />}
            />

            <Route
              path="/authentication"
              element={<AuthLayout children={<LoginOrRegister />} />}
            />

            <Route
              path="/account/basic-details"
              element={
                <AuthGuard
                  component={<AuthLayout children={<BasicUserDetailsForm />} />}
                />
              }
            />

            <Route
              path="/account/*"
              element={<AuthGuard component={<AccountLayout />} />}
            />
            <Route
              path="/competition-details/:competitionId"
              element={<CompetitionDetails />}
            />

            <Route
              path="terms-and-conditions"
              element={
                <div className="w-100 pt-4 pb-3">
                  <TermsAndConditions />
                </div>
              }
            />

            <Route path="*" element={<ErrorPage errorCode={404} />} />
          </Routes>
        </ScrollToTop>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRouter;
